import React from 'react'
import FooterTJW from '../components/FooterTJW'
import NavbarTJW from '../components/NavbarTJW'
import ProductViewTJW from '../components/ProductViewTJW'

import theBlue1 from '../images/theBlue/theBlue-1.jpg'
import theBlue2 from '../images/theBlue/theBlue-2.jpg'
import theBlue3 from '../images/theBlue/theBlue-3.jpg'
import theBlue4 from '../images/theBlue/theBlue-4.jpg'
import WatchHeaderTJW from '../components/WatchHeaderTJW'
import OrderStepperTJW from "../components/OrderStepperTJW";
import WatchCardsTJW from "../components/WatchCardsTJW";
import WatchDescriptionTJW from "../components/WatchDescriptionTJW";
import OrderWatchFormTJW from "../components/OrderWatchFormTJW";

export default function TheBlue() {
    const images = [theBlue1, theBlue2, theBlue3, theBlue4]

    return (
        <>
            <NavbarTJW/>
            <WatchHeaderTJW watch="The Blue"/>
            <div className="watch-info-container">
                <ProductViewTJW images={images} prodcut="The Blue"/>
                <WatchDescriptionTJW
                    assortment="Datejust 36"
                    size="36mm"
                    refNum="16234"
                    powerReserve="42h"
                    housing="Edelstahl"
                    bezel="Weißgold"
                    bracelet="Edelstahl"
                    glass="Saphirglas"
                    clockface="Blau"
                    manufacturingYear="1999"/>

            </div>
            <OrderStepperTJW/>
            <OrderWatchFormTJW assortment="The Blue"/>
            <WatchCardsTJW theGold={true} theBlack={true}/>
            <FooterTJW/>
        </>
    )
}
