import React from 'react'
import {useNavigate} from 'react-router-dom';
import theGoldCutout from '../images/the-gold-cutout.png';
import theBlueCutout from '../images/the-blue-cutout.png';
import theBlackCutout from '../images/the-black-cutout.png';
import {Card, Image, Text, Badge, Button, Group} from '@mantine/core';
import {Link} from 'react-router-dom';

import './styles/WatchCardsTJW.css'

export default function WatchCardsTJW({theBlue, theGold, theBlack}) {
    const navigate = useNavigate();

    function theBlueNavigate(e) {
        e.preventDefault();
        navigate('/theBlue');
    }

    function theGoldNavigate(e) {
        e.preventDefault();
        navigate('/theGold');
    }

    function theBlackNavigate(e) {
        e.preventDefault();
        navigate('/theBlack');
    }

    return (
        <div id="watch-card-container">

            {
                theBlue
                    ?
                    <Card shadow="sm" padding="lg" radius="xl" className='watch-card'>
                        <Card.Section>
                            <Image
                                src={theBlueCutout}
                                height={360}
                                alt="The Blue"
                                className='image-card-section'
                                onClick={(e) => theBlueNavigate(e)}
                            />
                        </Card.Section>

                        <Group position="apart" mt="md" mb="xs">
                            <Text weight={500} size='lg' className='watch-card-title'>THE BLUE</Text>
                        </Group>

                        <Text size="md" color="white">
                            The Blue ist eine Rolex Datejust 36 mit einem Gehäuse aus Edelstahl.
                            Sie ist 36mm groß und wurde im Jahr 1999 hergestellt.

                        </Text>

                        <Link to='/theblue' class='watch-link'>
                            <Button variant="dark" fullWidth mt="md" className='watch-card-button'>
                                Weitere Infos
                            </Button>
                        </Link>
                    </Card>
                    :
                    null
            }


            {
                theGold
                    ?
                    <Card shadow="sm" padding="lg" radius="xl" className='watch-card'>
                        <Card.Section>
                            <Image
                                src={theGoldCutout}
                                height={360}
                                alt="The Gold"
                                className='image-card-section'
                                onClick={(e) => theGoldNavigate(e)}
                            />
                        </Card.Section>

                        <Group position="apart" mt="md" mb="xs">
                            <Text weight={500} size='lg' className='watch-card-title'>THE GOLD</Text>
                            <Badge color="red" variant="light">
                                Ausverkauft
                            </Badge>
                        </Group>

                        <Text size="md" color="white">
                            The Gold ist eine Datejust 41 mit einem Gehäuse aus Edelstahl und Gelbgold.
                            Sie ist 36mm groß und wurde im Jahr 2020 hergestellt.
                        </Text>

                        <Link to='/thegold' class='watch-link'>
                            <Button variant="dark" fullWidth mt="md" className='watch-card-button'>
                                Weitere Infos
                            </Button>
                        </Link>
                    </Card>
                    :
                    null
            }

            {
                theBlack
                    ?
                    <Card shadow="sm" padding="lg" radius="xl" className='watch-card'>
                        <Card.Section>
                            <Image
                                src={theBlackCutout}
                                height={360}
                                alt="The Black"
                                className='image-card-section'
                                onClick={(e) => theBlackNavigate(e)}
                            />
                        </Card.Section>

                        <Group position="apart" mt="md" mb="xs">
                            <Text weight={500} size='lg' className='watch-card-title'>THE BLACK</Text>
                        </Group>

                        <Text size="md" color="white">
                            The Black ist eine Submariner Date mit einem Gehäuse aus Edelstahl.
                            Sie ist 36mm groß und wurde im Jahr 2006 hergestellt.
                        </Text>

                        <Link to='/theblack' class='watch-link'>
                            <Button variant="dark" fullWidth mt="md" className='watch-card-button'>
                                Weitere Infos
                            </Button>
                        </Link>
                    </Card>
                    :
                    null
            }


        </div>
    )
}
