import React from 'react'

import './styles/ThatsUsTJW.css'
import ceo from '../images/ceo-sitting-on-chair.jpg'
import {Button} from '@mantine/core'
import {useNavigate} from "react-router-dom";

export default function ThatsUsTJW() {

    const navigate = useNavigate();

    function contactNavigate(e) {
        e.preventDefault();
        navigate('/faq');
    }

    return (
        <div id="thats-us-container">
            <img src={ceo} alt="Alexander Tichonow" id='alexander-sitting-on-chair'/>
            <div id='thats-us-text-container'>
                <h3>Tichonow Jewelry&Watches!</h3>
                <p>We make Rolex affordable for everyone! </p>
                <p id='thats-us-desc'>Bei uns können Sie zu verschiedenen Anlässen ausgewählte Uhren,
                    Designer-Schmuck und vieles mehr mieten sowie auch käuflich erwerben.
                    Wir bieten Ihnen eine große Vielfalt an einzigartigen Produkten, sind
                    hoch diskret und beraten Sie auch gerne bei Ihrer Auswahl.<br/>
                    <br/>
                    Sie möchten neue Business-Fotos machen lassen? Oder Ihnen
                    steht ein wichtiges Geschäftsessen bevor, bei dem Sie sich von Ihrer
                    besten Seite präsentieren möchten? Dann sind unsere Produkte die
                    perfekte Ergänzung zu Ihnen selbst!
                </p>
                <Button variant="outline" color="yellow" onClick={(e) => contactNavigate(e)}>
                    Kontakt
                </Button>

            </div>
        </div>
    )
}
