import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

//Utils
import {
    HashRouter, Routes, Route
} from 'react-router-dom';
import { MantineProvider } from '@mantine/core';

import './base.css'
import 'bootstrap/dist/css/bootstrap.min.css';

//Pages
import Home from './pages/Home'
import Faq from './pages/Faq';
import Imprint from './pages/Imprint';
import Terms from './pages/Terms';
import Watches from './pages/Watches';
import TheBlue from './pages/TheBlue';
import TheGold from './pages/TheGold';
import TheBlack from './pages/TheBlack';
import ScrollToTop from "./hooks/ScrollToTop";

ReactDOM.createRoot(document.getElementById('root')).render(
    <MantineProvider withGlobalStyles withNormalizeCS>

        <HashRouter>
            <ScrollToTop/>
            <Routes>

                <Route index path='/' element={<Home/>}/>
                <Route path='/home' element={<Home/>}/>
                <Route path='/faq' element={<Faq/>}/>
                <Route path='/impressum' element={<Imprint/>}/>
                <Route path='/allgemeine-geschaeftsbedingungen' element={<Terms/>}/>
                <Route path='/watches' element={<Watches/>}/>
                <Route path='/theblue' element={<TheBlue/>}/>
                <Route path='/thegold' element={<TheGold/>}/>
                <Route path='/theblack' element={<TheBlack/>}/>

            </Routes>
        </HashRouter>

    </MantineProvider>
);

reportWebVitals();
