import React, {useEffect, useState} from 'react'

import {TextInput, Button, Group, Box, Dialog, Alert, createStyles, px, em, Checkbox} from '@mantine/core';
import {useForm} from '@mantine/form';
import {useDisclosure} from '@mantine/hooks';
import {DatePickerInput, DatesProvider} from '@mantine/dates';

import './styles/OrderWatchFormTJW.css'
import 'dayjs/locale/de'

export default function OrderWatchFormTJW({assortment}) {
    const [opened, {toggle, close}] = useDisclosure(false);
    const [onlyOneDay, setOnlyOneDay] = useState(false);
    const [timeSpan, setTimeSpan] = useState();
    const [singleDay, setSingleDay] = useState();

    useEffect(() => {
        setTimeout(() => close(), 3500)
    }, [opened, close])

    const useStyles = createStyles(() => ({
        container: {
            maxWidth: px(500),

            [`@media (max-width: ${em(630)})`]: {
                maxWidth: px(350),
            },
        },
    }));

    const form = useForm({
        initialValues: {
            name: '',
            email: '',
            days: '',
            day: '',
            address: '',
            city: '',
            postalCode: '',
            terms: false,
            assortment: ''
        },

        validate: {
            name: (nameVal) => (/^[a-z \u00df\u00fc\u00dc\u00f6\u00d6\u00e4\u00c4,.'-]+$/i.test(nameVal) ? null : "Vor- und Nachname!"),
            email: (emailVal) => (/^[\w-.]+@([\w-]+\.)+[\w-]{2,}$/.test(emailVal) ? null : 'Ungültige E-Mail!'),
            terms: (termsVal) => termsVal ? null : "Bitte akzeptieren sie unsere AGB's",
            address: (addressVal) => addressVal ? null : "Bitte geben Sie Ihre Straße und Hausnummer ein",
            city: (cityVal) => cityVal ? null : "Bitte geben Sie Ihre Stadt ein",
            postalCode: (postalCodeVal) => (/^[0-9´\s]+$/i).test(postalCodeVal) ? null : "Die Postleitzahl darf nur Zahlen beinhalten!"
        },
    });

    const {classes} = useStyles(undefined, undefined)

    function handleSubmit(values) {
        values.days = timeSpan;
        values.day = singleDay;
        values.assortment = assortment;

        fetch("/", {
            method: "POST",
            headers: {"Content-Type": "application/x-www-form-urlencoded"},
            body: encode({"form-name": "order", ...values})
        })
            .then(toggle)
            .catch(error => alert(error));
    }

    function encode(data) {
        return Object.keys(data)
            .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
            .join("&");
    }

    function oneDayChanger(event) {
        setOnlyOneDay(event.currentTarget.checked)
    }

    return (
        <div id='order-form-main'>
            <h2 id='order-header'>Mieten</h2>

            <Box className={classes.container} mx="auto">
                <form onSubmit={form.onSubmit((values) => handleSubmit(values))}
                      className={classes.container}>
                    <TextInput
                        withAsterisk
                        label="Name"
                        placeholder="Vor- und Nachname"
                        {...form.getInputProps('name')}
                    />

                    <TextInput
                        withAsterisk
                        label="Email"
                        placeholder="ihre@email.de"
                        {...form.getInputProps('email')}
                    />

                    {
                        !onlyOneDay ?
                            <DatesProvider settings={{locale: 'de', firstDayOfWeek: 1, weekendDays: [0]}}>
                                <DatePickerInput
                                    type="range"
                                    label="Zeitraum"
                                    withAsterisk
                                    placeholder="Miet Zeitraum"
                                    value={timeSpan}
                                    onChange={setTimeSpan}
                                    valueFormat="DD.MM.YYYY"
                                    minDate={new Date()}
                                />
                            </DatesProvider>
                            :
                            <DatesProvider settings={{locale: 'de', firstDayOfWeek: 1, weekendDays: [0]}}>
                                <DatePickerInput
                                    label="Tag"
                                    withAsterisk
                                    value={singleDay}
                                    onChange={setSingleDay}
                                    placeholder="Miet Tag"
                                    valueFormat="DD.MM.YYYY"
                                    minDate={new Date()}
                                    defaultDate={new Date()}
                                />
                            </DatesProvider>
                    }

                    <Checkbox
                        className='order-input'
                        label="Ein tages miete"
                        mt="xs"
                        checked={onlyOneDay}
                        color="yellow"
                        onChange={(event) => oneDayChanger(event)}
                    />

                    <TextInput
                        withAsterisk
                        label="Adresse"
                        placeholder="Ihre Adresse"
                        description="Straße und Hausnummer"
                        mt="md"
                        {...form.getInputProps('address')}
                    />

                    <Group className="d-flex" grow
                    >

                        <TextInput
                            withAsterisk
                            label="Stadt"
                            placeholder="Ihre Stadt"
                            {...form.getInputProps('city')}
                        />

                        <TextInput
                            withAsterisk
                            label="Postleitzahl"
                            placeholder="PLZ"
                            {...form.getInputProps('postalCode')}
                        />

                    </Group>

                    <Checkbox
                        className='order-input'
                        mt="md"
                        label="Ich habe die AGB's gelesen und akzeptiere sie"
                        {...form.getInputProps('terms', {type: 'checkbox'})}
                        color="yellow"
                    />


                    <Group position="right" mt="md" w='100'>
                        <Button variant='outline' radius="md" type="submit"
                                id='order-form-submit-button'>Senden</Button>
                    </Group>
                </form>
            </Box>

            <Dialog opened={opened} onClose={close} size="lg" radius="md" id='order-success'>
                <Alert title="Erfolg!" color="green" radius="md">
                    Deine Nachricht wurde erfolgreich gesendet
                </Alert>
            </Dialog>
        </div>
    )
};
