import React from 'react'

import './styles/Watches.css'
import NavbarTJW from '../components/NavbarTJW';
import FooterTJW from '../components/FooterTJW';
import WatchCardsTJW from '../components/WatchCardsTJW';


export default function Watches() {

    return (
        <>
            <NavbarTJW/>
            <h1 id='watches-title'>Unsere aktuelle Auswahl</h1>
            <WatchCardsTJW theBlue={true} theGold={true} theBlack={true}/>
            <FooterTJW/>
        </>
    )
}
