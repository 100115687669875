import React from 'react';

import './styles/WatchDescriptionTJW.css'

export default function WatchDescriptionTJW({
                                                size,
                                                assortment,
                                                refNum,
                                                powerReserve,
                                                housing,
                                                bezel,
                                                bracelet,
                                                glass,
                                                clockface,
                                                manufacturingYear
                                            }) {
    return (
        <div className="watch-desc">
            <table>
                <tbody>
                <tr>
                    <td>Modell:</td>
                    <td>{assortment}</td>
                </tr>
                </tbody>
                <tbody>
                <tr>
                    <td>Größe:</td>
                    <td>{size}</td>
                </tr>
                </tbody>
                <tbody>
                <tr>
                    <td>Referenznummer:</td>
                    <td>{refNum}</td>
                </tr>
                </tbody>
                <tbody>
                <tr>
                    <td>Gangreserve:</td>
                    <td>{powerReserve}</td>
                </tr>
                </tbody>

                <tbody>
                <tr>
                    <td>Herstellungsjahr:</td>
                    <td>{manufacturingYear}</td>
                </tr>
                </tbody>
            </table>

            <div id="hori-divider"></div>

            <table>
                <tbody>
                <tr>
                    <td>Material Gehäuse:</td>
                    <td>{housing}</td>
                </tr>
                </tbody>
                <tbody>
                <tr>
                    <td>Material Lünette:</td>
                    <td>{bezel}</td>
                </tr>
                </tbody>
                <tbody>
                <tr>
                    <td>Material Armband:</td>
                    <td>{bracelet}</td>
                </tr>
                </tbody>
                <tbody>
                <tr>
                    <td>Glas:</td>
                    <td>{glass}</td>
                </tr>
                </tbody>
                <tbody>
                <tr>
                    <td>Zifferblatt:</td>
                    <td>{clockface}</td>
                </tr>
                </tbody>

            </table>
        </div>
    );
};
