import React from 'react'

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import NavIcon from '../images/logo-white.svg'

import './styles/NavbarTJW.css'

export default function NavbarTJW() {
    return (
        <>
            <Navbar bg='custom' variant="dark" id='tjw-navbar' sticky="top">
                <Container>
                    <Navbar.Brand href="/">
                        <img
                            src={NavIcon}
                            width="40"
                            height="40"
                            alt="React Bootstrap logo"
                        />
                    </Navbar.Brand>
                    <Nav className="d-flex">
                        <Nav.Link href="/">Home</Nav.Link>
                        <Nav.Link href="/#/watches">Watches</Nav.Link>
                        <Nav.Link href="https://tichonow-jewelry.shop" nrel="noopener noreferrer">Kaufen</Nav.Link>
                        <Nav.Link href="/#/faq">FAQ</Nav.Link>
                    </Nav>
                </Container>
            </Navbar>
        </>
    )
}
