import React from 'react'
import ContactFormTJW from '../components/ContactFormTJW'
import FaqQuestionsTJW from '../components/FaqQuestionsTJW'
import FooterTJW from '../components/FooterTJW'
import NavbarTJW from '../components/NavbarTJW'

import './styles/Faq.css'

export default function Faq() {
    return (
        <div id='faq-main'>
            <NavbarTJW />
            <div id="faq-title-container">
                <h1 id='faq-title'>FAQ</h1>
                <h2 id='faq-subheader'>Häufig gestellte Fragen</h2>
            </div>
            <FaqQuestionsTJW />
            <ContactFormTJW />
            <FooterTJW />
        </div>
    )
}
