import React from 'react'
import ContactFormTJW from '../components/ContactFormTJW'
import FooterTJW from '../components/FooterTJW'
import NavbarTJW from '../components/NavbarTJW'

import './styles/Terms.css'

export default function Terms() {
    return (
        <>
            <NavbarTJW/>
            <h1 id='terms-title'>AGB's</h1>
            <div className='d-flex justify-content-center' id='terms-content-container'>
                <div id='terms-content'>

                    <p className="terms-detail-header">
                        § 1 Geltungsbereich
                    </p>
                    <p className="terms-detail-text">
                        1. Diese Allgemeinen Geschäftsbedingungen der Tichonow Jewelry & Watches, Inhaber Herr Alexander
                        Tichonow, Zur Isaraue 19, 94431 Pilsting,
                        auch handelnd unter "www.tichonow-jewelry.com", gelten für alle Mietverträge, die ein
                        Verbraucher oder Unternehmer mit dem Vermieter hinsichtlich
                        der am Geschäftssitz angebotenen oder auf der Website des Vermieters dargestellten Armbanduhren
                        abschließt. Hiermit wird der Einbeziehung von eigenen
                        Bedingungen des Mieters widersprochen, es sei denn, es ist zwischen den Parteien ausdrücklich
                        ein anderes vereinbart. <br/>
                        <br/>
                        2. Verbraucher im Sinne dieser AGB ist jede natürliche Person, die ein Rechtsgeschäft zu Zwecken
                        abschließt, die überwiegend weder ihrer gewerblichen noch ihrer selbständigen beruflichen
                        Tätigkeit
                        zugerechnet werden können. Unternehmer im Sinne dieser AGB ist eine natürliche oder juristische
                        Person oder eine rechtsfähige Personengesellschaft,
                        die bei Abschluss eines Rechtsgeschäfts in Ausübung ihrer gewerblichen oder selbständigen
                        beruflichen Tätigkeit handelt.
                    </p>
                    <br/>
                    <p className="terms-detail-header">
                        § 2 Allgemeine Informationen zur Miete unserer Armbanduhren
                    </p>
                    <p className="terms-detail-text">
                        1. Bei dem Mietgegenstand handelt es sich um ein Luxusgut, dass aufwendig in Handarbeit
                        gefertigt wurde und
                        der besonderen Pflege und Vorsicht im Umgang erfordert.
                        Der Mieter ist sich bewusst, dass der Mietgegenstand einen Wert darstellt und deshalb vor
                        Diebstahl ausreichend zu sichern ist.<br/>
                        <br/>
                        2. Der Mietgegenstand wird in einer firmeneigenen Transportbox zur Verfügung gestellt.
                        Die Rückgabe des Mietgegenstands hat mit der bereitgestellten Transportbox zu erfolgen.
                        Die zum Mietgegenstand gehörigen Originalverpackung und Dokumente verbleiben beim Vermieter
                        und sind nicht Gegenstand der Mietvereinbarung.<br/>
                        <br/>
                        3. Die angegebenen Preise sind Gesamtpreise, die eine gegebenenfalls anfallende gesetzliche
                        Umsatzsteuer enthalten.
                        Gegebenenfalls zusätzlich anfallende Liefer- und Versandkosten werden von dem Vermieter
                        gesondert angegeben.
                    </p>
                    <br/>
                    <p className="terms-detail-header">
                        § 3 Allgemeine Bedingungen zur Überlassung von Mietgegenständen
                    </p>
                    <p className="terms-detail-text">
                        1. Die dem Mieter gemäß Vertrag zur Miete auf Zeit überlassenen Mietgegenstände bleiben
                        uneingeschränktes Eigentum des
                        Vermieters und sind entsprechend sorgfältig zu behandeln. Durch unsachgemäße Behandlung oder
                        sonst verschuldet notwendig werdende
                        Reparaturen werden außerhalb der Vereinbarung des Mietvertrages dem Mieter in Rechnung gestellt.
                        Basis für diese Berechnung
                        ist ein Zustands- und Reparaturbericht, der dem Mieter zwecks Kenntnisnahme und Prüfung
                        zugeleitet wird.<br/>
                        <br/>
                        2. Vorbehaltlich der Regelung unter § 4 für den Onlinevertragsschluss und soweit nicht
                        vertraglich anders vereinbart,
                        ist der Mietgegenstand von dem Mieter am Geschäftssitz des Vermieters abzuholen und dort
                        zurückzugeben. Ein Versand des
                        Mietgegenstands ist – vorbehaltlich der Regelungen unter § 4 dieser Vereinbarung zum
                        Onlinevertragsschluss – nicht geschuldet.
                        Übernimmt der Vermieter ausnahmsweise auf Wunsch des Mieters den Versand des Mietgegenstands,
                        geschieht dies vorbehaltlich
                        anderslautender Vereinbarung zwischen den Parteien auf Rechnung und Gefahr des Mieters. Die
                        Kosten einer angemessenen
                        Transportversicherung sind von dem Mieter zu tragen. Ist dem Mieter ausnahmsweise der
                        Rückversand durch den Vermieter
                        gestattet, geht die Gefahr des zufälligen Untergangs oder der Beschädigung des Mietgegenstands
                        erst mit Auslieferung
                        bei dem Vermieter auf diesen über. Versandkosten sind von dem Mieter zu tragen. Der Mieter darf
                        sich ausschließlich
                        eines auf den Versand hochwertiger Luxusgüter spezialisierten Versandunternehmens bedienen und
                        hat den Mietgegenstand
                        entsprechend seinem Versicherungswert gegen Transport-, Elementar- und Wasserschäden, Diebstahl
                        und Verlust zu versichern.<br/>
                        <br/>
                        3. Über die Dauer der Überlassung des Mietgegenstands ist in aller Regel im Vertrag
                        entsprechende Vereinbarungen getroffen.
                        Grundsätzlich wird die Mietgebühr vom Tag der Bereitstellung zur Abholung bis zur Rückgabe des
                        Mietgegenstands erhoben. Bei
                        Versand des Mietgegenstands fällt die Mietgebühr vom Tag der Anlieferung beim Mieter bis zum
                        vereinbarten Mietende an, sofern
                        die Rückgabe oder Übergabe am darauffolgenden Tag erfolgt. Es gelten die Kalendertage.<br/>
                        <br/>
                        4. Die Dauer des Mietverhältnisses ist im Mietvertrag festgelegt. Unter- oder Überschreitungen
                        der Mietdauer sind mit dem
                        Vermieter abzustimmen und bedürfen dessen vorheriger Zustimmung. Rückgabe des Mietgegenstands
                        kann grundsätzlich nur während
                        der regulären Geschäftszeiten des Vermieters erfolgen. Soweit nach dem Vertrag eine Rücksendung
                        des Mietgegenstandes dem
                        Mieter gestattet ist, hat der Mieter dem Vermieter unverzüglich den Rückversand oder die
                        Versandbereitschaft des
                        Mietgegenstandes mitzuteilen. Wird der Mietgegenstand später als im Vertrag vereinbart
                        zurückgegeben, so verlängert
                        sich die Mietzeit jeweils um die im Vertrag festgelegte Zeiteinheit (Tag/Woche/ Monat).<br/>
                        <br/>
                        5. Bei Verlust oder Zerstörung des/der Mietgegenstände wird dem Mieter der Verkehrswert zum
                        Zeitpunkt des Verlustes,
                        mindestens jedoch der von dem Vermieter gezahlte Kaufpreis in Rechnung gestellt. Dem Mieter wird
                        das Recht eingeräumt,
                        dem Vermieter einen gegebenenfalls abweichenden Wert des Mietgegenstandes nachzuweisen.<br/>
                        <br/>
                        6. Der Vermieter ist bestrebt, die Mietgegenstände stets in einwandfreiem Zustand an den Mieter
                        auszuliefern. Über den
                        Zustand des Mietgegenstands erhält der Mieter von dem Vermieter bei Vertragsschluss ein
                        Zustandsprotokoll. Der Mieter
                        ist verpflichtet bei Übernahme des Mietgegenstands diesen zu überprüfen und feststellbare Mängel
                        oder Fehler sowie
                        Abweichungen von dem Zustandsprotokoll unverzüglich dem Vermieter anzuzeigen. Etwa verspätet
                        gemeldete Mängel oder
                        Fehler am Mietgegenstand, ebenfalls wie die entstandenen Folgeschäden, gehen zu Lasten des
                        Mieters.<br/>
                        <br/>
                        7. Der Mieter ist verpflichtet ihm überlassene Gebrauchsanleitungen und Pflegehinweise zur
                        Kenntnis zu nehmen und den
                        Mietgegenstand während der Mietdauer gemäß den Anweisungen des Vermieters im Rahmen des
                        Zumutbaren und im Allgemeinen
                        pfleglich zu behandeln. Kennzeichnungen des Mietgegenstands, insbesondere Schilder, Nummern oder
                        Aufschriften, dürfen
                        nicht entfernt, verändert oder unkenntlich gemacht werden.<br/>
                        <br/>
                        8. Eine Verbringung des Mietgegenstands aus dem Gebiet der Bundesrepublik Deutschland sowie in
                        die bundesdeutschen
                        Gebieten von Büsingen, der Insel Helgoland, der Freizonen im Sinne des Artikels 243 des
                        Zollkodex der Union (Freihäfen),
                        der Gewässer und Watten zwischen der Hoheitsgrenze und der jeweiligen Strandlinie sowie der
                        deutschen Schiffe und der
                        deutschen Luftfahrzeuge in Gebieten, die zu keinem Zollgebiet gehören, ist dem Mieter während
                        der Mietdauer
                        ohne ausdrückliche Genehmigung des Vermieters nicht gestattet. Der Vermieter weist gegenüber dem
                        Mieter daraufhin, dass die
                        Ein- und Ausfuhr des Mietgegenstands ohne Herkunftsnachweis zu erheblichen Problemen bei der
                        Zollabfertigung und zur
                        Erhebung von Zollgebühren, Steuern und Bußgeldern führen kann. Der Vermieter übernimmt hierfür
                        keine Haftung.<br/>
                        <br/>
                        9. Der Mieter ist ohne Erlaubnis des Vermieters nicht berechtigt, den Gebrauch an dem
                        Mietgegenstand einem Dritten
                        zu überlassen, insbesondere diese zu vermieten oder zu verleihen.<br/>
                        <br/>
                        10. Der Vermieter übernimmt während der Mietdauer etwa auftretenden allfällige Reparaturen,
                        sofern sie nicht durch
                        äußere Gewalteinwirkung oder unsachgemäße Handhabung entstehen (Wechsel von Verschleißteilen
                        ausgenommen). Erfüllungsort
                        für Reparaturen ist der Sitz des Vermieters. Transportkosten oder Kosten für Wege- und
                        Arbeitszeit für eine gewünschte
                        Reparatur „vor Ort” sind vom Mieter zu tragen. Schäden am Mietgegenstand und dessen Verlust sind
                        dem Vermieter unverzüglich
                        anzuzeigen. Der Mieter verpflichtet sich bei der Feststellung des Schadenshergangs gegenüber
                        einem Versicherer des
                        Vermieters mitzuwirken. Auf Wunsch des Mieters und mit Genehmigung des Vermieters vorgenommene
                        Anpassungen und/oder
                        Änderungen des Mietgegenstands sind gesondert zu vergüten, soweit sie nicht zur Instandhaltung
                        bzw. Instandsetzung
                        des Mietgegenstands, bzw. zur Sicherung des vertragsmäßigen Gebrauchs erforderlich sind.<br/>
                        <br/>
                        11. Der Mieter hat keinen Anspruch auf Ersatz von durch den Mietgegenstand entstandene Schäden
                        gegen den Vermieter.
                        Das gilt jedoch nicht für die Haftung des Vermieters für Schäden aus der Verletzung des Lebens,
                        des Körpers oder
                        der Gesundheit, die auf einer fahrlässigen Pflichtverletzung des Vermieters oder einer
                        vorsätzlichen oder fahrlässigen
                        Pflichtverletzung eines gesetzlichen Vertreters oder Erfüllungsgehilfen des Vermieters beruhen.
                        Für sonstige
                        Schäden haftet der Vermieter trotz Haftungsausschlusses, wenn sie auf einer grob fahrlässigen
                        Pflichtverletzung
                        des Vermieters oder auf einer vorsätzlichen oder grob fahrlässigen Pflichtverletzung eines
                        gesetzlichen Vertreters
                        oder Erfüllungsgehilfen des Vermieters beruhen.
                    </p>
                    <br/>
                    <p className="terms-detail-header">
                        § 4 Zusatzbedingungen für den Onlinevertragsschluss
                    </p>
                    <p className="terms-detail-text">
                        1. Diese Bedingungen gelten ausschließlich für den Onlinevertragsschluss und gehen den
                        Regelungen unter § 1 bis § 3
                        dieser Vertragsbedingungen vor.<br/>
                        <br/>
                        2. Die auf der Website des Vermieters beschriebenen Mietgegenstände stellen keine verbindlichen
                        Angebote seitens des
                        Vermieters dar, sondern dienen zur Abgabe eines verbindlichen Angebots auf Abschluss eines
                        Mietvertrages durch den Mieter.<br/>
                        <br/>
                        3. Der Mieter kann das Angebot über das in die Website des Vermieters integrierte
                        Online-Bestellformular abgeben.
                        Dabei gibt der Mieter, nachdem er die ausgewählten Mietgegenstände in den virtuellen Warenkorb
                        gelegt und den
                        elektronischen Bestellprozess durchlaufen hat, durch Klicken des den Bestellvorgang
                        abschließenden Buttons ein
                        rechtlich verbindliches Vertragsangebot in Bezug auf die im Warenkorb enthaltenen
                        Mietgegenstände ab.<br/>
                        <br/>
                        4. Der Vermieter kann das Angebot des Mieters innerhalb von fünf Tagen annehmen, indem er dem
                        Mieter eine schriftliche
                        Auftragsbestätigung oder eine Auftragsbestätigung in Textform (Fax oder E-Mail) übermittelt,
                        wobei insoweit der Zugang der
                        Auftragsbestätigung beim Mieter maßgeblich ist, oder indem er dem Mieter den Mietgegenstand
                        überlässt, wobei insoweit
                        der Zugang des Mietgegenstands beim Kunden maßgeblich ist, oder indem er den Mieter nach Abgabe
                        von dessen Bestellung
                        zur Zahlung auffordert. Liegen mehrere der vorgenannten Alternativen vor, kommt der Vertrag in
                        dem Zeitpunkt zustande, in
                        dem eine der vorgenannten Alternativen zuerst eintritt. Nimmt der Vermieter das Angebot des
                        Mieters innerhalb
                        vorgenannter Frist nicht an, so gilt dies als Ablehnung des Angebots mit der Folge, dass der
                        Mieter nicht mehr
                        an seine Willenserklärung gebunden ist. Die Frist zur Annahme des Angebots beginnt am Tag nach
                        der Absendung des Angebots
                        durch den Mieter zu laufen und endet mit dem Ablauf des fünften Tages, welcher auf die Absendung
                        des Angebots folgt.<br/>
                        <br/>
                        5. Bei der Abgabe eines Angebots über das Online-Bestellformular des Vermieters wird der
                        Vertragstext nach dem Vertragsschluss
                        vom Vermieter gespeichert und dem Mieter nach Absendung von dessen Bestellung in Textform (z. B.
                        E-Mail, Fax oder Brief)
                        übermittelt. Eine zusätzliche Zugänglichmachung des Vertragstextes durch den Vermieter erfolgt
                        nicht.<br/>
                        <br/>
                        6. Vor verbindlicher Abgabe der Bestellung über das Online-Bestellformular des Vermieters kann
                        der Mieter mögliche
                        Eingabefehler durch aufmerksames Lesen der auf dem Bildschirm dargestellten Informationen
                        erkennen. Ein wirksames technisches
                        Mittel zur besseren Erkennung von Eingabefehlern kann dabei die Vergrößerungsfunktion des
                        Browsers sein, mit deren Hilfe die
                        Darstellung auf dem Bildschirm vergrößert wird. Seine Eingaben kann der Mieter im Rahmen des
                        elektronischen Bestellprozesses
                        so lange über die üblichen Tastatur- und Mausfunktionen korrigieren, bis er einen den
                        Bestellvorgang abschließenden Button anklickt.<br/>
                        <br/>
                        7. Für den Vertragsschluss steht ausschließlich die deutsche Sprache zur Verfügung.<br/>
                        <br/>
                        8. Die Bestellabwicklung und Kontaktaufnahme finden in der Regel per E-Mail und automatisierter
                        Bestellabwicklung statt.
                        Der Kunde hat sicherzustellen, dass die von ihm zur Bestellabwicklung angegebene E-Mail-Adresse
                        zutreffend ist, so dass
                        unter dieser Adresse die vom Vermieter versandten E-Mails empfangen werden können. Insbesondere
                        hat der Kunde bei dem Einsatz von
                        SPAM-Filtern sicherzustellen, dass alle vom Vermieter oder von diesem mit der Bestellabwicklung
                        beauftragten Dritten versandten
                        E-Mails zugestellt werden können.<br/>
                        <br/>
                        9. Verbrauchern steht grundsätzlich ein Widerrufsrecht nach Maßgabe der §§ 312ff., 346ff. BGB
                        zu.<br/>
                        <br/>
                        10. Nähere Informationen zum Widerrufsrecht ergeben sich aus der Widerrufsbelehrung des
                        Vermieters.<br/>
                        <br/>
                        11. Die Überlassung des Mietgegenstands erfolgt in Abweichung der Regelung unter § 3 Ziff. 3
                        dieser Vertragsbedingungen
                        grundsätzlich auf dem Versandweg an die vom Mieter angegebene Lieferanschrift. Dabei ist die im
                        Bestellprozess des Vermieters
                        angegebene Lieferanschrift maßgeblich, sofern nichts anderes vereinbart ist. Die Kosten für den
                        Versand, einschließlich Rückgabe,
                        sind im Preis inbegriffen. Der Mieter ist verpflichtet, die Rückgabe über das von dem Vermieter
                        beauftragte Transportunternehmen
                        nach dessen Maßgabe abzuwickeln. Der Vermieter ist verpflichtet den Mieter über den
                        Rückgabeprozess bei Vertragsschluss
                        ausreichend zu informieren.<br/>
                        <br/>
                        12. Sendet das Transportunternehmen den versandten Mietgegenstand an den Vermieter zurück, da
                        eine Zustellung beim Mieter
                        nicht möglich war, trägt der Mieter die Kosten für den erfolglosen Versand. Dies gilt nicht,
                        wenn der Mieter sein Widerrufsrecht
                        wirksam ausübt, wenn er den Umstand, der zur Unmöglichkeit der Zustellung geführt hat, nicht zu
                        vertreten hat oder wenn er
                        vorübergehend an der Annahme der angebotenen Leistung verhindert war, es sei denn, dass der
                        Vermieter ihm die Leistung eine
                        angemessene Zeit vorher angekündigt hatte.
                    </p>
                </div>
            </div>
            <ContactFormTJW/>
            <FooterTJW/>
        </>
    )
}
