import React, { useState, useEffect } from "react";

import './styles/ProductViewTJW.css'

export default function ProductViewTJW({ prodcut, images }) {

    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImageIndex((currentIndex) =>
                currentIndex === images.length - 1 ? 0 : currentIndex + 1
            );
        }, 7000);
        return () => clearInterval(interval);
    }, [images]);

    const handleThumbnailClick = (index) => {
        setCurrentImageIndex(index);
    };

    const aspectRatio =
        images[currentImageIndex].height / images[currentImageIndex].width;

    return (
        <div className="product-image-carousel">
            <div className="thumbnails">
                {images.map((image, index) => (
                    <img
                        key={index}
                        src={image}
                        alt={`Thumbnail ${index}`}
                        className={`thumbnail ${currentImageIndex === index ? "active" : ""
                            }`}
                        onClick={() => handleThumbnailClick(index)}
                    />
                ))}
            </div>
            <div className="main-image-container" style={{ paddingTop: `${aspectRatio * 100}%` }}>
                <img src={images[currentImageIndex]} alt={prodcut} className="main-image" />
            </div>

        </div>
    );
}
