import React, { useEffect } from 'react'

import { TextInput, Button, Group, Box, Textarea, Dialog, Alert, createStyles, px, em, Checkbox } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useDisclosure } from '@mantine/hooks';

import './styles/ContactFormTJW.css'

export default function ContactFormTJW() {
    const [opened, { toggle, close }] = useDisclosure(false);

    useEffect(() => {
        setTimeout(() => close(), 3500)
    }, [opened, close])

    const useStyles = createStyles(() => ({
        container: {
            maxWidth: px(500),

            [`@media (max-width: ${em(630)})`]: {
                maxWidth: px(350),
                toggle
            },
        },
    }));

    const form = useForm({
        initialValues: {
            name: '',
            email: '',
            phone: '',
            message: '',
            terms: false,
        },

        validate: {
            name: (nameVal) => (/^[a-z \u00df\u00fc\u00dc\u00f6\u00d6\u00e4\u00c4,.'-]+$/i.test(nameVal) ? null : "Vor- und Nachname!"),
            email: (emailVal) => (/^[\w-.]+@([\w-]+\.)+[\w-]{2,}$/.test(emailVal) ? null : 'Ungültige E-Mail!'),
            phone: (phoneVal) => (/(\(?([\d \-)–+/(]+){6,}\)?([ .\-–/]?)([\d]+))/i.test(phoneVal) ? null : 'Ungültige Telefonnummer!'),
            message: (messageVal) => messageVal ? null : 'Bitte gib eine Nachricht ein!',
            terms: (termsVal) => termsVal ? null : "Bitte akzeptieren sie unsere AGB's"

        },
    });

    const { classes } = useStyles()

    function handleSubmit(values, event) {

        fetch("/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: encode({ "form-name": "contact", ...values })
        })
            .then(toggle)
            .catch(error => alert(error));

        event.preventDefault();
    }

    const encode = (data) => {
        return Object.keys(data)
            .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
            .join("&");
    }

    return (
        <div id='contact-form-main'>
            <h2 id='contact-header'>Kontakt</h2>

            <Box className={classes.container} mx="auto">
                <form onSubmit={form.onSubmit((values, event) => handleSubmit(values, event))}
                    className={classes.container}>
                    <TextInput
                        withAsterisk
                        label="Name"
                        placeholder="Vor- und Nachname"
                        {...form.getInputProps('name')}
                    />

                    <TextInput
                        withAsterisk
                        label="Email"
                        placeholder="ihre@email.de"
                        {...form.getInputProps('email')}
                    />

                    <TextInput
                        withAsterisk
                        label="Telefonnummer"
                        placeholder="+49 000 00000"
                        {...form.getInputProps('phone')}
                    />

                    <Textarea
                        withAsterisk
                        label='Ihre Nachricht'
                        placeholder='Nachricht'
                        {...form.getInputProps('message')}
                        autosize
                        minRows={2}
                        maxRows={4}
                    />

                    <Checkbox
                        className='contact-input'
                        mt="md"
                        label="Ich habe die AGB's gelesen und akzeptiere sie"
                        {...form.getInputProps('terms', { type: 'checkbox' })}
                    />

                    <Group position="right" mt="md" w='100'>
                        <Button variant='outline' radius="md" type="submit"
                            id='contact-form-submit-button'>Senden</Button>
                    </Group>
                </form>
            </Box>

            <Dialog opened={opened} onClose={close} size="lg" radius="md" id='contact-success'>
                <Alert title="Erfolg!" color="green" radius="md">
                    Deine Nachricht wurde erfolgreich gesendet
                </Alert>
            </Dialog>
        </div>
    )
}
