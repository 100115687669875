import React from 'react'
import {Link} from 'react-router-dom'

import NavIcon from '../images/logo-white.svg'

import './styles/FooterTJW.css'

export default function FooterTJW() {

    function scrollToTop() {
        window.scrollTo(0, 0);
    }

    return (
        <div id="footer-main">
            <div id="footer-top-row">
                <div id='footer-image-container'>

                    <img id='footer-logo' src={NavIcon} alt="Company Logo" onClick={() => scrollToTop()}/>
                </div>
                <div className='footer-column' id='company-location'>
                    <span>Tichonow Jewelry&Watches  </span> <br/>
                    <span>Zur Isaraue 19  </span> <br/>
                    <span>94431 Pilsting  </span> <br/>
                    <span>Bayern </span>
                </div>

                <div className="footer-column" id='footer-links'>
                    <Link class='footer-link' to='/'>Home</Link>
                    <Link class='footer-link' to='/faq'>FAQ</Link>
                    <Link class='footer-link' to='/impressum'>Impressum</Link>
                    <Link class='footer-link' to='/allgemeine-geschaeftsbedingungen'>AGB's</Link>
                </div>
            </div>
            <div id="footer-bottom-row">
                <h3 id='footer-copyright'>
                    © Tichonow Jewelery
                </h3>
            </div>
        </div>
    )
}
