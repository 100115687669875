import React, { useRef } from 'react'
import useWindowDimensions from '../hooks/windowDimensions'
import FooterTJW from '../components/FooterTJW'
import NavbarTJW from '../components/NavbarTJW'
import { Carousel } from '@mantine/carousel';

import './styles/Home.css'
import theGoldCutout from '../images/the-gold-cutout.png';
import theBlueCutout from '../images/the-blue-cutout.png';
import theBlackCutout from '../images/the-black-cutout.png';
import homeBackground from '../images/home-background.webp';
import goldenGradientLogo from '../images/logo-golden-gradient.svg'
import { BackgroundImage } from '@mantine/core';
import WatchCardsTJW from '../components/WatchCardsTJW';
import Autoplay from 'embla-carousel-autoplay';
import ThatsUsTJW from '../components/ThatsUsTJW';
import { useNavigate } from "react-router-dom";
import ContactFormTJW from "../components/ContactFormTJW";

export default function Home() {

    const { height, width } = useWindowDimensions();
    const autoplay = useRef(Autoplay({ delay: 3000 }));

    const navigate = useNavigate();

    function theBlueNavigate(e) {
        e.preventDefault();
        navigate('/theBlue');
    }

    function theGoldNavigate(e) {
        e.preventDefault();
        navigate('/theGold');
    }

    function theBlackNavigate(e) {
        e.preventDefault();
        navigate('/theBlack');
    }

    return (
        <div id='home-main-container'>

            <BackgroundImage
                src={homeBackground}
                h={700}
            >
                <NavbarTJW />

                <h1 id='main-page-header' className='d-flex align-items-center justify-content-center'>

                    <span>Tichonow</span> <br />
                    Jewelry & Watches
                </h1>
                {
                    width > 755 && height !== 0
                        ?
                        <div id="watch-assortment">
                            <div className="watch-container">
                                <img src={theBlueCutout} alt="The Blue Rolex Watch" className='watch-assortment-image'
                                    onClick={(e) => theBlueNavigate(e)}
                                />
                            </div>
                            <div className="watch-container">
                                <img src={theGoldCutout} alt="The Black Rolex Watch"
                                    className='watch-assortment-image'
                                    onClick={(e) => theGoldNavigate(e)}
                                />
                            </div>
                            <div className="watch-container">
                                <img src={theBlackCutout} alt="The Black Rolex Watch"
                                    className='watch-assortment-image'
                                    onClick={(e) => theBlackNavigate(e)}
                                />
                            </div>
                        </div>
                        :
                        <div id="watch-assortment-carousel">
                            <Carousel maw={300} mx="auto" withIndicators height={260} loop plugins={[autoplay.current]}
                                onMouseEnter={autoplay.current.stop} onMouseLeave={autoplay.current.reset}>
                                <Carousel.Slide id='test'
                                    onClick={(e) => theBlueNavigate(e)}
                                >
                                    <div className="watch-container-carousel" id='test'>
                                        <img src={theBlueCutout} alt="The Blue Rolex Watch"
                                            className='watch-assortment-image' />
                                    </div>
                                </Carousel.Slide>
                                <Carousel.Slide id='test'
                                    onClick={(e) => theGoldNavigate(e)}
                                >
                                    <div className="watch-container-carousel" id='test'>
                                        <img src={theGoldCutout} alt="The Black Rolex Watch"
                                            className='watch-assortment-image' />
                                    </div>
                                </Carousel.Slide>
                                <Carousel.Slide id='test' //TODO Test Id removed
                                    onClick={(e) => theBlackNavigate(e)}
                                >
                                    <div className="watch-container-carousel" id='test'>
                                        <img src={theBlackCutout} alt="The Black Rolex Watch"
                                            className='watch-assortment-image' />
                                    </div>
                                </Carousel.Slide>
                            </Carousel>
                        </div>
                }

            </BackgroundImage>

            {/* <BuyInsteadOfRentTJW /> */}

            <div id="why-us-main">
                <h2 id='why-us-heading'>Warum mieten?</h2>

                <div id='why-us-graphic-container'>
                    <div className="why-us-text">
                        <p className="text">
                            Keine Wartungs- oder Reparaturkosten
                        </p>
                        <p className="text text-left">
                            Kein finanzielles Risiko aufgrund der Miete
                        </p>
                        <p className="text">
                            Möglichkeit neue Modelle zu testen
                        </p>
                    </div>

                    <img src={goldenGradientLogo} alt="Logo with Golden Gradient" id='why-us-middle-logo' />

                    <div className="why-us-text">
                        <p className="text">
                            Uhr nach Anlass oder Vorliebe wählen
                        </p>
                        <p className="text text-right">
                            Flexible Auswahl der verschiedenen Modelle
                        </p>
                        <p className="text">
                            Oft kosteneffektiver als ein Eigenkauf
                        </p>
                    </div>
                </div>

            </div>

            <WatchCardsTJW theBlue={true} theGold={true} theBlack={true} />
            <h2 id='why-us-heading'>Das sind wir!</h2>
            <ThatsUsTJW />
            <ContactFormTJW />
            <FooterTJW />
        </div>
    )
}
