import React from 'react'
import ContactFormTJW from '../components/ContactFormTJW'
import FooterTJW from '../components/FooterTJW'
import NavbarTJW from '../components/NavbarTJW'

import './styles/Imprint.css'

export default function Imprint() {
    return (
        <>
            <NavbarTJW/>
            <h1 id='imprint-title'>Impressum</h1>
            <div className='d-flex justify-content-center' id='imprint-content-container'>
                <div id='imprint-content'>

                    <p className="imprint-detail-header">
                        Anbieterinformation
                    </p>
                    <p className="imprint-detail-text">
                        Anbieter der Website www.tichonow-jewelry.de und der über diese Website aufrufbaren Seiten in
                        den sozialen Netzwerken ist Herr Alexander Tichonow, Inh. Tichonow Jewelry.<br/>
                        <br/>
                        Die Geschäftsanschrift lautet: <br/>
                        Zur Isaraue 19, 94431 Pilsting <br/>
                        <br/>
                        (Telefon +49 (0) 152 59842569 E-Mail: info@tichonow-jewelry.de )
                    </p>
                    <br/>
                    <p className="imprint-detail-header">
                        Umsatzsteueridentifikationsnummer
                    </p>
                    <p className="imprint-detail-text">
                        Die Umsatzsteueridentifikationsnummer nach § 27a UStG lautet DE 350 657 334
                    </p>
                    <br/>
                    <p className="imprint-detail-header">
                        Online-Streitbeilegung und Verbraucherstreitbeilegung
                    </p>
                    <p className="imprint-detail-text">
                        Es besteht keine Verpflichtung und keine Bereitschaft zur Teilnahme an einem
                        Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle.
                    </p>
                    <br/>
                    <p className="imprint-detail-header">
                        Anwendbares Recht
                    </p>
                    <p className="imprint-detail-text">
                        Die rechtlichen Hinweise auf dieser Seite sowie alle Fragen und Streitigkeiten im Zusammenhang
                        mit dieser Website und den über diese Website aufrufbaren Seiten in den sozialen Netzwerken
                        unterliegen dem Recht der Bundesrepublik Deutschland.
                    </p>
                </div>
            </div>
            <ContactFormTJW/>
            <FooterTJW/>
        </>
    )
}
