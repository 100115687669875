import {IconDeviceWatchHeart, IconMailCheck, IconShieldCheckFilled, IconCircleCheck} from '@tabler/icons-react';
import { useNavigate } from "react-router-dom";
import {Stepper} from '@mantine/core';
import './styles/OrderStepperTJW.css'

export default function OrderStepperTJW() {
    const navigate = useNavigate();

    return (
        <div className="stepper-bg">
            <Stepper
                /*allowNextStepsSelect={false}*/
                active={1}
                color="yellow"
                completedIcon={<IconCircleCheck/>}
                breakpoint={631}

                className="order-stepper">

                    <Stepper.Step completedIcon={<IconDeviceWatchHeart size="1.8rem"/>} label="Schritt 1"
                                  description="Eine Uhr aussuchen"
                                  allowStepClick={true}
                                  onClickCapture={() => navigate('/watches')} />

                <Stepper.Step icon={<IconMailCheck size="1.8rem"/>}
                              label="Schritt 2"
                              description="Antrag stellen"
                              allowStepClick={true}
                              onClickCapture={() => navigate('/faq')} />

                <Stepper.Step icon={<IconShieldCheckFilled size="1.8rem"/>}
                              label="Schritt 3"
                              description="Uhr sicher mieten"
                              allowStepClick={true}
                              onClickCapture={() => navigate('/faq')} />
            </Stepper>
        </div>
    );
}
