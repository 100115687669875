import React from 'react'
import FooterTJW from '../components/FooterTJW'
import NavbarTJW from '../components/NavbarTJW'
import ProductViewTJW from '../components/ProductViewTJW'

import theGold1 from '../images/theGold/theGold-1.jpg'
import theGold2 from '../images/theGold/theGold-2.jpg'
import theGold3 from '../images/theGold/theGold-3.jpg'
import theGold4 from '../images/theGold/theGold-4.jpg'
import './styles/Watch.css'
import WatchHeaderTJW from '../components/WatchHeaderTJW'
import WatchDescriptionTJW from "../components/WatchDescriptionTJW";
import OrderStepperTJW from "../components/OrderStepperTJW";
import OrderWatchFormTJW from "../components/OrderWatchFormTJW";
import WatchCardsTJW from "../components/WatchCardsTJW";

export default function TheGold() {
    const images = [theGold1, theGold2, theGold3, theGold4]

    return (
        <>
            <NavbarTJW/>
            <WatchHeaderTJW watch="The Gold"/>

            <div className="watch-info-container">
                <ProductViewTJW images={images} prodcut="The Gold"/>
                <WatchDescriptionTJW
                    assortment="Datejust 41"
                    size="41mm"
                    refNum="126333"
                    powerReserve="70h"
                    housing="Edelstahl/Gelbgold"
                    bezel="Gelbgold"
                    bracelet="Edelstahl/Gelbgold"
                    glass="Saphirglas"
                    clockface="Weiß"
                    manufacturingYear="2020"/>

            </div>
            <OrderStepperTJW/>
            <OrderWatchFormTJW assortment="The Gold"/>
            <WatchCardsTJW theBlue={true} theBlack={true}/>
            <FooterTJW/>
        </>
    )
}
