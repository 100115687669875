import React from 'react'
import FooterTJW from '../components/FooterTJW'
import NavbarTJW from '../components/NavbarTJW'
import ProductViewTJW from '../components/ProductViewTJW'

import theBlack1 from '../images/theBlack/theBlack-1.jpg'
import theBlack2 from '../images/theBlack/theBlack-2.jpg'
import theBlack3 from '../images/theBlack/theBlack-3.jpg'
import theBlack4 from '../images/theBlack/theBlack-4.jpg'
import WatchHeaderTJW from '../components/WatchHeaderTJW'
import OrderStepperTJW from "../components/OrderStepperTJW";
import WatchCardsTJW from "../components/WatchCardsTJW";
import WatchDescriptionTJW from "../components/WatchDescriptionTJW";
import OrderWatchFormTJW from "../components/OrderWatchFormTJW";

export default function TheBlack() {
    const images = [theBlack1, theBlack2, theBlack3, theBlack4]

    return (
        <>
            <NavbarTJW/>
            <WatchHeaderTJW watch="The Black"/>
            <div className="watch-info-container">
                <ProductViewTJW images={images} prodcut="The Black"/>
                <WatchDescriptionTJW
                    assortment="Submariner Date"
                    size="40mm"
                    refNum="16610"
                    powerReserve="48h"
                    housing="Edelstahl"
                    bezel="Keramik"
                    bracelet="Edelstahl"
                    glass="Saphirglas"
                    clockface="Schwarz"
                    manufacturingYear="2006"/>

            </div>
            <OrderStepperTJW/>
            <OrderWatchFormTJW assortment="The Black"/>
            <WatchCardsTJW theBlue={true} theGold={true}/>
            <FooterTJW/>
        </>
    )
}
