import React from 'react'

import {Accordion} from '@mantine/core';

import './styles/FaqQuestionsTJW.css'

export default function FaqQuestions() {
    return (
        <div id='questions-main-container'>
            <div className="question-container left-questions">
                <Accordion class='faq-accordion'>
                    <Accordion.Item value="damage">
                        <Accordion.Control>Was passiert im Schadensfall?</Accordion.Control>
                        <Accordion.Panel>Ein Schaden muss schnellstmöglich per Mail oder Telefon an uns gemeldet werden.
                            Sollte ein Diebstahl vorliegen muss dieser an uns und zusätzlich an die Polizei gemeldet
                            werden.</Accordion.Panel>
                    </Accordion.Item>
                </Accordion>

                <Accordion class='faq-accordion'>
                    <Accordion.Item value="sings-of-wear">
                        <Accordion.Control>Werden Gerbrauchsspuren berechnet?</Accordion.Control>
                        <Accordion.Panel>Normale Gebrauchsspuren oder kleine oberflächige Kratzer am Gehäuse sind kein
                            Problem und werden nicht berechnet. </Accordion.Panel>
                    </Accordion.Item>
                </Accordion>

                <Accordion class='faq-accordion'>
                    <Accordion.Item value="return-time">
                        <Accordion.Control>Wann muss ich die Uhr zurückgegeben haben?</Accordion.Control>
                        <Accordion.Panel>Die Dauer des Mietverhältnisses ist im Mietvertrag festgelegt. Unter- oder
                            Überschreitungen der Mietdauer sind mit Tichonow Jewelry&Watches abzustimmen und bedürfen
                            dessen vorheriger Zustimmung.</Accordion.Panel>
                    </Accordion.Item>
                </Accordion>

                <Accordion class='faq-accordion'>
                    <Accordion.Item value="price">
                        <Accordion.Control>Wie viel kostet die Miete einer Uhr?</Accordion.Control>
                        <Accordion.Panel>Die Mietkosten einer Uhr variieren je nach Mietdauer und Modell.
                        Eine Mietdauer von 7 Tagen kann bis zu 300€ kosten.
                        Kontaktieren Sie uns für ein individuelles Angebot entsprechend ihrer Mietanforderungen</Accordion.Panel>
                    </Accordion.Item>
                </Accordion>
            </div>
            <div className="question-container right-questions">
                <Accordion class='faq-accordion'>
                    <Accordion.Item value="travel">
                        <Accordion.Control>In welche Länder darf ich mit den Mietgegenständen
                            reisen?</Accordion.Control>
                        <Accordion.Panel>Unsere Mietgegenstände dürfen nur in Deutschland genutzt
                            werden!</Accordion.Panel>
                    </Accordion.Item>
                </Accordion>

                <Accordion class='faq-accordion'>
                    <Accordion.Item value="damage-case">
                        <Accordion.Control>Wer zahlt im Schadensfall?</Accordion.Control>
                        <Accordion.Panel>Schäden am Gehäuse, Glas, Mechanismus oder Band durch unsachgemäßen oder grob
                            fahrlässigen Umgang mit den Mietgegenständen werden nicht von unserer Versicherung
                            übernommen, und somit an den Kunden weiterberechnet.</Accordion.Panel>
                    </Accordion.Item>
                </Accordion>

                <Accordion class='faq-accordion'>
                    <Accordion.Item value="retunr-time">
                        <Accordion.Control>Wie kann ich bezahlen?</Accordion.Control>
                        <Accordion.Panel>Wir bieten Dir die Zahlungsmöglichkeiten bar, Überweisung und Paypal
                            an.</Accordion.Panel>
                    </Accordion.Item>
                </Accordion>

                <Accordion class='faq-accordion'>
                    <Accordion.Item value="pickup-return">
                        <Accordion.Control>Wie funktioniert die Abholung und Rückgabe?</Accordion.Control>
                        <Accordion.Panel>Die Abholung und Rückgabe der gemieteten Uhr erfolgt in unseren
                            Geschäftsräumen oder kann per Kurierdienst erfolgen.
                            Bitte kontaktieren Sie uns im Voraus, um die
                            Abholung und Rückgabe zu vereinbaren.</Accordion.Panel>
                    </Accordion.Item>
                </Accordion>
            </div>
        </div>
    )
}
